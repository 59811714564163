import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UtilsService } from './utils.service';
import { Observable } from 'rxjs';
import { Period } from './date-time.service';

@Injectable()
export class GaugeService {

  constructor(
    private httpClient: HttpClient,
  ) {}

  public getGaugeData(siteId: string, ruleType: number, grainType: number, period: Period): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: {
        siteId: siteId,
        ruleType: ruleType.toString(),
        grainType: grainType.toString(),
        from: UtilsService.DateToYYYYMMDD_HHMMSS(period.start_date),
        to: UtilsService.DateToYYYYMMDD_HHMMSS(period.start_date)
      }
    });
    return this.httpClient.get('/api/rules/data', {params: httpParams})
                          .pipe(map(data => data || null))
  }

  public getGaugeWidgets(selectedSiteId): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: {
        siteId: selectedSiteId
      }
    });
    return this.httpClient.get<any>('/api/rules/types', {params: httpParams})
                          .pipe(map(data => data || null));
  }
}
